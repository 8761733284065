import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../src/components/Ecosystem.svg";
import './App.css'

const navigation = [
  { name: "Home", href: "#" },
  { name: "About", href: "#" },
  { name: "Community", href: "#" },
  { name: "Blog", href: "#" },
];

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            ></a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src={logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  ></a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <section id="main">
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Empowering growth in digital innovation ecosystems
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Committed to giving ecosystem actors a space to come together
                and work together as they continue to offer a variety of
                services that will influence the direction of the Nigerian
                economy in the future.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#eco"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a
                  href="#about"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </section>
      <section class=" bg-blueGray-200 -mt-24" id="about">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-1 shadow-lg rounded-lg">
                <div class="px-4 flex-auto"></div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-16">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Who is Ecosytem Labs
              </h3>
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Ecosystem Labs is a Nigerian-based company focused on empowering
                and fostering the growth of the digital, innovation, startup,
                technology, and entrepreneurship ecosystems in the country.
              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                We are dedicated to providing a platform for ecosystem actors to
                converge and collaborate, as they continue to provide a wide
                range of services that will help shape the future of the
                Nigerian economy.
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-2 shadow-lg rounded-lg bg-pink-500">
                <img
                  alt="..."
                  src="https://images.pexels.com/photos/1181360/pexels-photo-1181360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  class="w-full align-middle rounded-t-lg"
                ></img>
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  ></svg>

                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    The Founders of Ecosystem Labs have a combined 75 plus
                    years’ experience as leaders, entrepreneurs, public policy
                    experts, development specialists, our multi-disciplinary
                    team brings deep experience to the table.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="py-12 md:py-14 bg-coolGray-50" id="eco">
        <div class="container mx-auto px-4">
          <div class="max-w-4xl px-8 mb-20 mx-auto text-center">
            <p class="text-xl lg:text-2xl tracking-tight mb-10">
              <span>
                The Omniverse is our branded annual summit which brings together
                all the key players in our community for a week of&nbsp;
              </span>
              <span class="italic text-coolGray-600">
                product launches, policy dialogues, funding and education&nbsp;
              </span>
              <span>
                programming as well as our own patented Honours and awards
                platform as we seek to recognize the leading lights in our
                ecosystem from leaders in the public and private sectors,
                academia, finance, education, start-up’s and entrepreneurs.
              </span>
            </p>
            <a
              class="group inline-flex items-center leading-none font-medium pb-2 border-b-2 border-black"
              href="https://www.youromniverse.org/"
              target="_blank"
            >
              <span class="mr-4">The Omniverse</span>
              <span class="group-hover:rotate-45 transform transition duration-100">
                <svg
                  width="11"
                  height="11"
                  viewbox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 1.5L1.5 9.5"
                    stroke="black"
                    stroke-width="1.3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M9.5 8.83571V1.5H2.16429"
                    stroke="black"
                    stroke-width="1.3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </section>
      <section class="py-4 bg-white overflow-hidden">
        <div class="container px-4 mx-auto">
          <div class="max-w-xl text-center mx-auto mb-20">
            <h2 class="font-heading mb-5 text-6xl tracking-tighter">
              About The ECO Community
            </h2>
            <p class="text-xl tracking-tight">
              Our Members' platform is designed to provide verified networking,
              deal flow, industry news, and thought leadership. It hosts a blog
              that covers various topics, including funding, academic community,
              hubs and accelerators, multilaterals, and MDA's of the government.
              Our goal is to aggregate mindshare and voiceshare that is relevant
              to the industry to enable inclusive growth, affect public policy
              and provide key agenda framing for the ecosystem. We regularly
              publish market updates and have developed an ecosystem newsletter
              to keep our members informed
            </p>
          </div>
        </div>
      </section>
      <section class="pt-24 bg-white">
        <div class="px-12 mx-auto max-w-7xl">
          <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
            <h1 class="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-3xl md:tracking-tight">
              <span>Our Blog,</span>{" "}
              <span class="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-green-400 to-purple-500 lg:inline">
                {" "}
                The Update!
              </span>{" "}
              <span></span>
            </h1>
            <p class="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
              Our Blog, The Update! is designed to aggregate all the important
              news relevant to our community and bring them together from the
              various feeds and the editorial team. Over time we will grow our
              own identity and develop a podcast series to enable deep dives
              into some of the content and issues that our community is most
              concerned about.
            </p>
            <p class="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
              Our name Ecosystem Labs reflects our commitment to building an
              ecosystem conducive to innovation and growth. We provide the
              platform for entrepreneurs, investors, incubators, and other
              ecosystem players to connect and collaborate effectively. At
              Ecosystem Labs, we aim to build forward by fostering a vibrant and
              sustainable ecosystem for the benefit of all stakeholders.
            </p>
          </div>
        </div>
      </section>
      <div className="spacer"></div>
    </div>
  );
}
export default App;
